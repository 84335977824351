<template>
  <loading-dialog :loading="$apollo.queries.singleApp.loading">
    <base-page-layout :title="appObj ? `Configurar ${clientName}` : 'Configuração de App'">
      <v-tabs v-if="appObj" v-model="tab" grow class="mb-5">
        <v-tab v-for="tab in tabs" :key="tab" ripple>{{ tab }}</v-tab>

        <v-tab-item :transition="false" :reverse-transition="false">
          <app-settings :app.sync="appObj" :clientName="clientName" :dbName="dbName" :accountId="accountId" @refresh="refresh" />
        </v-tab-item>

        <v-tab-item v-if="showWebColors" disabled :transition="false" :reverse-transition="false">
          <web-colors-scheme :app.sync="appObj" :clientName="clientName" :accountId="accountId" @refresh="refresh" />
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false">
          <app-assets :app.sync="appObj" :accountId="accountId" @refresh="refresh" />
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false">
          <app-cashback :accountId="accountId" />
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false">
          <app-metadata :app.sync="appObj" :accountId="accountId" />
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false">
          <app-firebase :app.sync="appObj" :accountId="accountId" />
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false">
          <app-general :app.sync="appObj" :accountId="accountId" />
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false">
          <app-buttons :app="appObj" :accountId="accountId" />
        </v-tab-item>
      </v-tabs>

      <v-tabs v-else grow class="mb-5">
        <v-tab>Configurar novo app</v-tab>
        <v-tab-item :transition="false" :reverse-transition="false">
          <app-creation :default-app-colors="defaultAppColors" :clientName="clientName" :default-web-colors="defaultWebColors" :accountId="accountId" />
        </v-tab-item>
      </v-tabs>

      <!-- views -->
      <router-view />
    </base-page-layout>
  </loading-dialog>
</template>

<script>
import { QUERY_SINGLE_APP, QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'
export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue'),
    AppSettings: () => import('@/modules/accounts/components/app/AppSettings.vue'),
    AppAssets: () => import('@/modules/accounts/components/app/AppAssets.vue'),
    AppMetadata: () => import('@/modules/accounts/components/app/AppMetadata.vue'),
    AppFirebase: () => import('@/modules/accounts/components/app/AppFirebase.vue'),
    AppCashback: () => import('@/modules/accounts/components/app/AppCashback.vue'),
    AppGeneral: () => import('@/modules/accounts/components/app/AppGeneral.vue'),
    WebColorsScheme: () => import('@/modules/accounts/components/app/WebColorsScheme.vue'),
    AppCreation: () => import('@/modules/accounts/components/app/CreateApp.vue'),
    AppButtons: () => import('@/modules/accounts/components/app/AppButtons.vue')
  },
  data: () => ({
    accountId: '',
    clientName: '',
    dbName: '',
    tab: -1,
    appObj: null,
    appName: null,
    defaultAppColors: null,
    defaultWebColors: null
  }),
  computed: {
    tabs() {
      const tabs = ['Settings', 'Assets', 'Cashback', 'Metadata', 'Firebase', 'Gerais', 'Botões']
      if (this.appObj.ecommerce_v2?.web?.active) tabs.splice(1, 0, 'Web - Esquema de cores')
      return tabs
    },
    showWebColors() {
      return this.appObj.ecommerce_v2 ? this.appObj.ecommerce_v2?.web?.active : false
    }
  },
  apollo: {
    singleApp: {
      query: QUERY_SINGLE_APP,
      fetchPolicy: 'network-only',
      variables() {
        return {
          account_id: this.accountId
        }
      },
      update({ singleApp }) {
        this.clientName = singleApp?.name
        this.defaultAppColors = singleApp?.defaultAccountApp?.channels?.app?.build?.colors
        this.defaultWebColors = singleApp?.defaultAccountApp?.channels?.consumer_app?.assets?.colors
        this.appObj = singleApp?.channels?.app?.build
        this.appName = singleApp?.channels?.app?.build?.app_name
        this.appObj.consumer_app = singleApp?.channels?.consumer_app
        this.appObj.ecommerce_v2 = singleApp?.channels?.ecommerce_v2
        this.appObj.club = singleApp?.channels?.club
      },
      error(err) {
        console.log(err?.message)
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['app_config']
        }
      }
    },
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      result({ data }) {
        this.dbName = data.adminAccount?.db_name
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    refresh() {
      this.$apollo.queries.singleApp.refetch()
    }
  }
}
</script>
